<template>
<div class="one-technique">
    <div class="one-technique-top">
        <h1>Комбайн зернозбиральний </h1>
        <p>John deere S680і (2016 рік)</p>
    </div>
    <div class="one-technique-item">
        <div class="one-technique-left">
            <div class="one-technique-left-top">
                <h1>3 650 000 грн</h1>
                <p>В наявності</p>
            </div>
            <div class="one-tchnique-item-list">
                <div class="one-technique-list-item" v-for="i in 11" :key="i">
                    <p>Стан</p>
                    <p class="op8">Новий</p>
                </div>

            </div>
        </div>
        <div class="one-technique-right">
            <div class="one-technique-carousel">
                <div class="technique-carousel">
                    <VueSlickCarousel v-bind="settings" ref="carousel" @afterChange="CarouselChange">
                        <div v-for="i in 4" :key="i">
                            <div class="technique-one-image">
                                <div class="cover"></div>
                                <img src="@/assets/img/big-car.svg" alt="">
                            </div>
                        </div>

                    </VueSlickCarousel>
                </div>

            </div>
            <div class="one-technique-carousel-control">
                <div class="technique-control-images" v-for="i in 4" :key="i" @click="$refs.carousel.goTo(i-1); activeImage=i">
                    <div class="technique-control-image" :class="activeImage==i? 'activeImage':''">
                        <img src="@/assets/img/leasingc.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="one-technique-text">
        <h1>Опис</h1>
        <p>Вилковий навантажувач підвищеної прохідності JCB 930 оснащений високоефективним двигуном JCB Dieselmax, потужність котрого складає 55 кВт. Трансмісія навантажувача JCB Synchro Shuttle маєвбудований гідротрансформатор, що дозволяє швидко змінювати напрямок руху, також жоступна функція бокового зміщення.</p>
        <div class="w-full flex justify-center">
            <router-link tag="a" to="/leasing/technique/get/1"> 
            <button class="button blue">Подати заявку</button>
            </router-link>
        </div>
    </div>
</div>
</template>

<script>
 import VueSlickCarousel from 'vue-slick-carousel'
 import 'vue-slick-carousel/dist/vue-slick-carousel.css'
 // optional style for arrows & dots
 import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
 export default {
     components: {
         VueSlickCarousel
     },
     data() {
         return {
             activeImage: 1,
             settings: {
                 "dots": true,
                 "dotsClass": "slick-dots custom-dot-class",
                 "edgeFriction": 0.35,
                 "infinite": true,
                 "speed": 500,
                 "slidesToShow": 1,
                 "slidesToScroll": 1
             }
         }
     },
     methods: {
         CarouselChange(event) {
             this.activeImage = event + 1
         }
     }
 }
</script>

<style lang="scss">
.one-technique{
    background: var(--white-bg);
}
.one-technique-top {
    padding: 250px 20px 100px 20px;
    background:var(--white-gray-bg);
    text-align: center;

    h1 {
        font-family: Stem-Medium;
        font-size: 40px;
        line-height: 42px;
        font-feature-settings: 'tnum'on, 'lnum'on;
        color: var(--other-black);
    }

    p {
        font-family: Stem-Medium;
        font-size: 24px;
        line-height: 28px;
        color: var(--white-black);
        margin-top: 20px;
    }
}

.one-technique-item {
    padding: 50px 100px;
    background: var(--white-bg);
    display: flex;

    .one-technique-left {
        padding: 0px 20px;
        max-width: 420px;
        width: 100%;
    }

    .one-technique-left-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;

        h1 {
            font-family: Stem-Medium;
            font-size: 30px;
            line-height: 42px;
            font-feature-settings: 'tnum'on, 'lnum'on;
            color: var(--other-black);
        }

        p {
            font-family: Stem-Medium;
            font-size: 1.125rem;
            line-height: 1.25rem;
            color: rgba(0, 224, 35, 0.8);
        }
    }

    .one-technique-list-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        border-bottom: 1px solid #DDDFE2;

        p {
            font-family: Stem-Regular;
            font-size: 1.125rem;
            line-height: 1.25rem;
            display: flex;
            align-items: center;
            color: var(--other-black);
        }
    }

    .one-technique-right {
        padding: 0px 20px;
        flex: 1;
        overflow: hidden;

    }

    .one-technique-carousel {
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;

    }

    .technique-carousel {
        max-width: 724px;
        overflow: hidden;
    }

    .technique-one-image {
        padding: 0px 20px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: 460px;
        position: relative;

        img {
            width: 100%;
        }

        .cover {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }

    .one-technique-carousel-control {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }

    .technique-control-images {
        padding: 10px;

        .technique-control-image {
            width: 170px;
            height: 140px;
            display: flex;
            align-items: center;
            overflow: hidden;
            cursor: pointer;
            transition: all 0.3s ease-in;

            img {
                width: 100%;
            }

        }

    }

    .activeImage {
        border-radius: 5px !important;
        opacity: 0.6;
        cursor: not-allowed !important;
    }

}

.one-technique-text {
    padding: 50px 120px;
    background: var(--white-bg);

    h1 {
        font-family: Stem-Medium;
        font-size: 30px;
        line-height: 42px;
        font-feature-settings: 'tnum'on, 'lnum'on;
        color: var(--other-black);
    }

    p {
        font-family: Stem-Regular;
        font-size: 1rem;
        line-height: 24px;
        color: var(--other-black);
        margin-top: 20px;
        margin-bottom: 60px;
    }
}

@media screen and(max-width:1400px) {
    .one-technique-item {
        .technique-control-images {
            .technique-control-image {
                width: 100px;
                height: 80px;
            }

        }
    }

}

@media screen and(max-width:1200px) {
    .one-technique-item {
        .technique-control-images {

            .technique-control-image {
                width: 80px;
                height: 60px;
            }

        }
    }
}

@media screen and(max-width:1100px) {
    .one-technique-top {
        padding: 200px 20px 100px 20px;
    }

    .one-technique-item {
        padding: 50px 0px;
    }

    .one-technique-text {
        padding: 50px 20px;

    }
}

@media screen and(max-width:900px) {
    .one-technique-top {
        padding: 150px 20px 50px 20px;
        background:var(--white-gray-bg);
        text-align: center;

        h1 {
            font-size: 24px;
            line-height: 28px;
        }

        p {
            font-size: 0.875rem;
            line-height: 1rem;
        }
    }

    .one-technique-item {
        padding: 50px 0px;
        background: var(--white-bg);
        display: flex;
        flex-direction: column-reverse;
        align-items: center;

        .one-technique-left {
            padding: 0px 20px;
            max-width: 100%;
            margin-top: 50px;
            width: 100%;
        }

        .one-technique-left-top {
            margin-bottom: 40px;

            h1 {
                font-size: 20px;
                line-height: 1.5rem;
            }

            p {
                font-size: 1rem;
                line-height: 1.125rem;
            }
        }

        .one-technique-list-item {
            p {
                font-size: 1rem;
                line-height: 1.125rem;
            }
        }

        .one-technique-right {
            width: 100%;

        }

        .technique-one-image {
            height: auto;
        }

        .one-technique-carousel-control {
            display: none;
        }

    }

    .one-technique-text {
        padding: 50px 20px;

        h1 {
            font-size: 24px;
            line-height: 28px;
            text-align: center;
        }

        p {
            font-size: 0.875rem;
            line-height: 1.25rem;
            text-align: center;
        }
    }
}
</style>
